import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://mainfarm.io',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    initialOpenState: true,
    items: [
      // {
      //  label: 'Swap',
      //  href: '/swap?outputCurrency=0x3bd460D1C7fd2A4Cf45b6c5abb206A1Ad7026685',
      // },
      {
        label: 'Liquidity',
        href: '/pool',
      },
      {
        label: 'Fiat',
        href: '/fiat',
      },
      {
        label: 'Cross Swap',
        href: 'https://swap.mainfarm.io/',
      },
      {
        label: 'Futures',
        href: 'https://futures.mainfarm.io/',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: 'https://mainfarm.io/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: 'https://mainfarm.io/syrup',
  },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: 'https://mainfarm.io/lottery',
  // },
  // {
  //   label: 'NFT',
  //   icon: 'NftIcon',
  //   href: 'https://mainfarm.io/nft',
  // },
  // {
  //   label: 'Teams & Profile',
  //   icon: 'GroupsIcon',
  //   calloutClass: 'rainbow',
  //   items: [
  //     {
  //       label: 'Leaderboard',
  //       href: 'https://mainfarm.io/teams',
  //     },
  //     {
  //       label: 'Task Center',
  //       href: 'https://mainfarm.io/profile/tasks',
  //     },
  //     {
  //       label: 'Your Profile',
  //       href: 'https://mainfarm.io/profile',
  //     },
  //   ],
  // },
  // {
  //   label: 'Info',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'Overview',
  //       href: 'https://pancakeswap.info',
  //     },
  //     {
  //       label: 'Tokens',
  //       href: 'https://pancakeswap.info/tokens',
  //     },
  //     {
  //       label: 'Pairs',
  //       href: 'https://pancakeswap.info/pairs',
  //     },
  //     {
  //       label: 'Accounts',
  //       href: 'https://pancakeswap.info/accounts',
  //     },
  //   ],
  // },
  // {
  //   label: 'IFO',
  //   icon: 'IfoIcon',
  //   href: 'https://mainfarm.io/ifo',
  // },
  {
     label: 'More',
     icon: 'MoreIcon',
     items: [
  //     {
  //       label: 'Voting',
  //       href: 'https://voting.mainfarm.io',
  //     },
        {
          label: 'Info',
          href: 'https://www.dextools.io/app/en/bnb/pair-explorer/0x7924cceb679f8c7a1a9ab0426dfaa4c4c766f852',
        },
       {
         label: 'Github',
         href: 'https://github.com/Mainfarm',
       },
       {
         label: 'Docs',
         href: 'https://docs.mainfarm.io',
       },
       {
        label: 'Audit',
        href: 'https://docs.mainfarm.io/security/audit',
      },
      {
        label: 'Community',
        href: 'https://docs.mainfarm.io/community',
      },
     ],
   },
]

export default config
