import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ButtonMenu, ButtonMenuItem } from '@pancakeswap-libs/uikit'
import useI18n from 'hooks/useI18n'

const StyledNav = styled.div`
  margin-bottom: 40px;
`
function Nav({ activeIndex = 0 }: { activeIndex?: number }) {
  const TranslateString = useI18n()
  return (
    <StyledNav>
      <ButtonMenu activeIndex={activeIndex} scale="sm" variant="subtle">
        { /* <ButtonMenuItem id="swap-nav-link" to="/swap?outputCurrency=0x3bd460D1C7fd2A4Cf45b6c5abb206A1Ad7026685" as={Link}>
          {TranslateString(1142, 'Swap')}
  </ButtonMenuItem> */ }
        <ButtonMenuItem id="pool-nav-link" to="/pool" as={Link}>
          {TranslateString(262, 'Liquidity')}
        </ButtonMenuItem>
        <ButtonMenuItem id="fiat-nav-link" to="/fiat" as={Link}>
          {TranslateString(262, 'Fiat')}
        </ButtonMenuItem>
        <ButtonMenuItem
          id="pool-nav-link"
          as="a"
          href="https://swap.mainfarm.io"
          target="_blank"
          rel="noreferrer noopener"
        >
          Cross Swap
        </ButtonMenuItem>
        <ButtonMenuItem
          id="pool-nav-link"
          as="a"
          href="https://futures.mainfarm.io"
          target="_blank"
          rel="noreferrer noopener"
        >
           Futures
        </ButtonMenuItem>
      </ButtonMenu>
    </StyledNav>
  )
}

export default Nav
