import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Button, CardBody, Text, Card, LinkExternal, Flex } from '@pancakeswap-libs/uikit'
import CardNav from 'components/CardNav'
import { RowBetween } from 'components/Row'
import { AutoColumn } from 'components/Column'
import useI18n from 'hooks/useI18n'
import FiatPageHeader from 'components/FiatPageHeader'
import FiatInfoHeaderLeft from 'components/FiatInfoHeaderLeft'
import FiatInfoHeaderRight from 'components/FiatInfoHeaderRight'
import AppBody from '../AppBody'


const FiatBlock = styled.div`
/*background-color: red;*/

`
 const FiatCardBody = styled(CardBody)`
  padding:24px 0px 24px 0px;
`

const MainWrapper = styled.div`
  max-width:1400px;
  margin:0px auto;
`

const ParFiat = styled.p`
  margin-bottom:50px;
`

const FiatImg = styled.img`
  display:block;
  height: 30px;
  max-width:260px;
  margin:20px 0px 0px 20px;
  ${({ theme }) => theme.mediaQueries.xs} {
    height: 40px;
  }
  
  ${({ theme }) => theme.mediaQueries.lg} {
    height: 50px;
  }
`

const FiatLogoBlock = styled.div`
  text-align:left;
`
const DepMet = styled.div`
  margin:2px 2px 2px 2px;
  padding:5px 10px;
  border-radius:20px;
  border:2px solid rgba(150,150,150,0.15);
`

const FiatHeader = styled.div`

`
const Cards = styled(Card)`
margin: 0px 0px 20px 0px;
width:  100%;
float: left;
${({ theme }) => theme.mediaQueries.xs} {
  width: calc( 100% - 40px);
  margin: 0px 20px 40px 20px;
}

${({ theme }) => theme.mediaQueries.lg} {
  margin: 0px 20px 40px 20px;
  width: calc( 50% - 40px);
}
`
const TopCards = styled(Card)`
box-shadow:none;
margin: 0px 0px 20px 0px;
width:  100%;
float: left;
${({ theme }) => theme.mediaQueries.xs} {
  width: calc( 100% - 40px);
  margin: 0px 20px 40px 20px;
}

${({ theme }) => theme.mediaQueries.lg} {
  margin: 0px 20px 40px 20px;
  width: calc( 50% - 40px);
}
`

const DepFlex = styled(Flex)`
  display: flex;
  flex-wrap: wrap;
`

const DepositText = styled(Text)`
  font-weight:bold;
  text-align: center;
`

export default function Fiat() {
  const theme = useContext(ThemeContext)
  const TranslateString = useI18n()

  return (
    <>
      <CardNav activeIndex={1} />
      <AppBody>
        <MainWrapper>
        <div style={{ display: "block", float: "left"}}>
        <TopCards style={{ background: "transparent"}}>
        <FiatBlock>
          <FiatHeader>
        <FiatInfoHeaderLeft
          title={TranslateString(262, 'Fiat Gateway Providers')}
        >
          <ParFiat>
          Below is a list of popular fiat gateways. It can be used to buy or sell crypto with a credit card ore bank transfer.
          </ParFiat>

        </FiatInfoHeaderLeft>
        </FiatHeader>
        
        </FiatBlock>
        </TopCards>

        <TopCards>
        <FiatBlock>
          <FiatHeader>
        <FiatInfoHeaderRight
          title={TranslateString(262, 'Fiat Services')}
        >
          <ParFiat>
          Fiat services on Mainfarm are provided by third-parties. Mainfarm is responsible or liable for the performance of these third-party services. Any claims and questions should be addressed with the selected provider directly.
          </ParFiat>

        </FiatInfoHeaderRight>
        </FiatHeader>
        
        </FiatBlock>
        </TopCards>
        </div>
        <Cards>
        <FiatBlock>
          <FiatHeader>
        <FiatLogoBlock>
          <FiatImg src="/images/fiat-mercury.svg"/>
        </FiatLogoBlock>
        <FiatPageHeader
          title={TranslateString(262, 'Available Operations')}
          description={TranslateString(1168, 'Fiat in / Fiat Out')}
        >
          <ParFiat>
          Mercuryo is a cross-border payment network providing global access to fast and cheap money transfers. Working together with industry leaders, Mercuryo offers a multi-currency widget that allows purchasing and selling crypto securely with the lowest fees.
          </ParFiat>
          <Button scale="md" variant="secondary" style={{ float: "left", width: "200px" }} margin="0px 10px 20px 10px" as={LinkExternal} href="https://exchange.mercuryo.io/">
            {TranslateString(168, ' BUY ')}
          </Button>
          <Button variant="tertiary" style={{ background: theme.colors.tertiary, width: "200px" }} margin="0px 10px 20px 10px" as={LinkExternal} href="https://exchange.mercuryo.io/?widget_id=d7702dc1-c8ee-4726-a5be-5f18e31849b6&currency=ETH&type=sell">
            {TranslateString(168, ' SELL ')}
          </Button>
        </FiatPageHeader>
        </FiatHeader>
        <AutoColumn gap="lg" justify="center">
          <FiatCardBody>
            <AutoColumn gap="12px" style={{ width: '100%' }}>
            <DepositText color={theme.colors.text}>{TranslateString(107, 'Deposit Methods: ')}</DepositText>
              <RowBetween padding="0 10px">
                <DepFlex>
                <DepMet>Credit Card</DepMet>
                <DepMet>Debit Card</DepMet>
                <DepMet>Apple Pay</DepMet>
                <DepMet>Google Pay</DepMet>
              </DepFlex>
              </RowBetween>
            </AutoColumn>
          </FiatCardBody>
        </AutoColumn>
        </FiatBlock>
        </Cards>

        <Cards>
        <FiatBlock>
          <FiatHeader>
        <FiatLogoBlock>
          <FiatImg src="/images/fiat-all-cash.svg"/>
        </FiatLogoBlock>
        <FiatPageHeader
          title={TranslateString(262, 'Available Operations')}
          description={TranslateString(1168, 'Fiat in / Fiat Out')}
        >
          <ParFiat>
          The main value we have is our reputation and the trust of our customers. More than 1000 exchange directions. The most popular currencies in one place. All the transactions in the ALL.CASH Service are performed in automatic or semi-automatic mode.
          </ParFiat>
          <Button scale="md" variant="secondary" style={{ float: "left", width: "200px" }} margin="0px 10px 20px 10px" as={LinkExternal} href="https://all.cash?refCode=22345115226">
            {TranslateString(168, ' BUY ')}
          </Button>
          <Button variant="tertiary" style={{ background: theme.colors.tertiary, width: "200px" }} margin="0px 10px 20px 10px" as={LinkExternal} href="https://all.cash?refCode=22345115226">
            {TranslateString(168, ' SELL ')}
          </Button>
        </FiatPageHeader>
        </FiatHeader>
        <AutoColumn gap="lg" justify="center">
          <FiatCardBody>
            <AutoColumn gap="12px" style={{ width: '100%' }}>
            <DepositText color={theme.colors.text}>{TranslateString(107, 'Deposit Methods: ')}</DepositText>
              <RowBetween padding="0 10px">
                <DepFlex>
                <DepMet>Debit Card</DepMet>
                <DepMet>Digital currencies</DepMet>
              </DepFlex>
              </RowBetween>
            </AutoColumn>
          </FiatCardBody>
        </AutoColumn>
        </FiatBlock>
        </Cards>

        <Cards>
        <FiatBlock>
          <FiatHeader>
        <FiatLogoBlock>
          <FiatImg src="/images/fiat-transak.svg"/>
        </FiatLogoBlock>
        <FiatPageHeader
          title={TranslateString(262, 'Available Operations')}
          description={TranslateString(1168, 'Fiat in / Fiat Out')}
        >
          <ParFiat>
          Transak is as a global fiat-to-crypto payment gateway which doubles as a developer integration. We solve the important problem of helping mainstream users and businesses access crypto and the blockchain directly by integrating local regulatory compliance, payment methods, and liquidity from around the world.
          </ParFiat>
          <Button scale="md" variant="secondary" style={{ float: "left", width: "200px" }} margin="0px 10px 20px 10px" as={LinkExternal} href="https://global.transak.com/">
            {TranslateString(168, ' BUY ')}
          </Button>
          <Button variant="tertiary" style={{ background: theme.colors.tertiary, width: "200px" }} margin="0px 10px 20px 10px" as={LinkExternal} href="https://global.transak.com/">
            {TranslateString(168, ' SELL ')}
          </Button>
        </FiatPageHeader>
        </FiatHeader>
        <AutoColumn gap="lg" justify="center">
          <FiatCardBody>
            <AutoColumn gap="12px" style={{ width: '100%' }}>
            <DepositText color={theme.colors.text}>{TranslateString(107, 'Deposit Methods: ')}</DepositText>
              <RowBetween padding="0 10px">
                <DepFlex>
                <DepMet>Apple/Google Pay</DepMet>
                <DepMet>Visa</DepMet>
                <DepMet>Mastercard</DepMet>
                <DepMet>American Express</DepMet>
                </DepFlex>
              </RowBetween>
            </AutoColumn>
          </FiatCardBody>
        </AutoColumn>
        </FiatBlock>
        </Cards>

        <Cards>
        <FiatBlock>
          <FiatHeader>
        <FiatLogoBlock>
          <FiatImg src="/images/fiat-swapcoin.svg"/>
        </FiatLogoBlock>
        <FiatPageHeader
          title={TranslateString(262, 'Available Operations')}
          description={TranslateString(1168, 'Fiat in / Fiat Out')}
        >
          <ParFiat>
          The service works around the clock 24/7 support and exchanges. More than 2500 exchange destinations. The average application processing time is 12 minutes. SSL-encryption of personal data. Verification of payments by security operators. Application processing quality control system. Multi-level server protection system.
          </ParFiat>
          <Button scale="md" variant="secondary" style={{ float: "left", width: "200px" }} margin="0px 10px 20px 10px" as={LinkExternal} href="https://swapcoin.cc/?refCode=22345097120">
            {TranslateString(168, ' BUY ')}
          </Button>
          <Button variant="tertiary" style={{ background: theme.colors.tertiary, width: "200px" }} margin="0px 10px 20px 10px" as={LinkExternal} href="https://swapcoin.cc/?refCode=22345097120">
            {TranslateString(168, ' SELL ')}
          </Button>
        </FiatPageHeader>
        </FiatHeader>
        <AutoColumn gap="lg" justify="center">
          <FiatCardBody>
            <AutoColumn gap="12px" style={{ width: '100%' }}>
            <DepositText color={theme.colors.text}>{TranslateString(107, 'Deposit Methods: ')}</DepositText>
              <RowBetween padding="0 10px">
                <DepFlex>
                <DepMet>Debit Card</DepMet>
                <DepMet>Digital currencies</DepMet>
                </DepFlex>
              </RowBetween>
            </AutoColumn>
          </FiatCardBody>
        </AutoColumn>
        </FiatBlock>
        </Cards>

        <Cards>
        <FiatBlock>
          <FiatHeader>
        <FiatLogoBlock>
          <FiatImg src="/images/fiat-crypster.svg"/>
        </FiatLogoBlock>
        <FiatPageHeader
          title={TranslateString(262, 'Available Operations')}
          description={TranslateString(1168, 'Fiat in / Fiat Out')}
        >
          <ParFiat>
          Crypster.net - service of high-quality and fast currency exchanges. We are favorably distinguished from others by a large number of exchange directions, and the presence of high reserves. We monitor our reputation - this is confirmed not only by the long-term operation of our service, but also by numerous reviews from our customers.
          </ParFiat>
          <Button scale="md" variant="secondary" style={{ float: "left", width: "200px" }} margin="0px 10px 20px 10px" as={LinkExternal} href="https://crypster.net?refCode=22345115231">
            {TranslateString(168, ' BUY ')}
          </Button>
          <Button variant="tertiary" style={{ background: theme.colors.tertiary, width: "200px" }} margin="0px 10px 20px 10px" as={LinkExternal} href="https://crypster.net?refCode=22345115231">
            {TranslateString(168, ' SELL ')}
          </Button>
        </FiatPageHeader>
        </FiatHeader>
        <AutoColumn gap="lg" justify="center">
          <FiatCardBody>
            <AutoColumn gap="12px" style={{ width: '100%' }}>
            <DepositText color={theme.colors.text}>{TranslateString(107, 'Deposit Methods: ')}</DepositText>
              <RowBetween padding="0 10px">
                <DepFlex>
                <DepMet>Debit Card</DepMet>
                <DepMet>Digital currencies</DepMet>
                </DepFlex>
              </RowBetween>
            </AutoColumn>
          </FiatCardBody>
        </AutoColumn>
        </FiatBlock>
        </Cards>

        <Cards>
        <FiatBlock>
          <FiatHeader>
        <FiatLogoBlock>
          <FiatImg src="/images/fiat-payget.svg"/>
        </FiatLogoBlock>
        <FiatPageHeader
          title={TranslateString(262, 'Available Operations')}
          description={TranslateString(1168, 'Fiat in / Fiat Out')}
        >
          <ParFiat>
          PAYGET allows you to make electronic currency exchanges anywhere in the world, wherever you are. More than 2500 exchange points. You can make exchanges with PAYGET from any device: mobile phone, tablet or computer. The main credo of the service is absolute transparency and honesty. You can be sure of the safety of your funds.
          </ParFiat>
          <Button scale="md" variant="secondary" style={{ float: "left", width: "200px" }} margin="0px 10px 20px 10px" as={LinkExternal} href="https://payget.pro/ref/code/22345115239">
            {TranslateString(168, ' BUY ')}
          </Button>
          <Button variant="tertiary" style={{ background: theme.colors.tertiary, width: "200px" }} margin="0px 10px 20px 10px" as={LinkExternal} href="https://payget.pro/ref/code/22345115239">
            {TranslateString(168, ' SELL ')}
          </Button>
        </FiatPageHeader>
        </FiatHeader>
        <AutoColumn gap="lg" justify="center">
          <FiatCardBody>
            <AutoColumn gap="12px" style={{ width: '100%' }}>
            <DepositText color={theme.colors.text}>{TranslateString(107, 'Deposit Methods: ')}</DepositText>
              <RowBetween padding="0 10px">
                <DepFlex>
                <DepMet>Debit Card</DepMet>
                <DepMet>Digital currencies</DepMet>
                </DepFlex>
              </RowBetween>
            </AutoColumn>
          </FiatCardBody>
        </AutoColumn>
        </FiatBlock>
        </Cards>

        <Cards>
        <FiatBlock>
          <FiatHeader>
        <FiatLogoBlock>
          <FiatImg src="/images/fiat-ychanger.svg"/>
        </FiatLogoBlock>
        <FiatPageHeader
          title={TranslateString(262, 'Available Operations')}
          description={TranslateString(1168, 'Fiat in / Fiat Out')}
        >
          <ParFiat>
          On our website you can quickly and reliably make an exchange of the electronic currency you are interested in. Your exchange data is not stored on our service and is not provided to third parties, which guarantees one hundred percent anonymity of completed exchanges. The service works seven days a week.
          </ParFiat>
          <Button scale="md" variant="secondary" style={{ float: "left", width: "200px" }} margin="0px 10px 20px 10px" as={LinkExternal} href="https://ychanger.net/?R=15716977308296">
            {TranslateString(168, ' BUY ')}
          </Button>
          <Button variant="tertiary" style={{ background: theme.colors.tertiary, width: "200px" }} margin="0px 10px 20px 10px" as={LinkExternal} href="https://ychanger.net/?R=15716977308296">
            {TranslateString(168, ' SELL ')}
          </Button>
        </FiatPageHeader>
        </FiatHeader>
        <AutoColumn gap="lg" justify="center">
          <FiatCardBody>
            <AutoColumn gap="12px" style={{ width: '100%' }}>
            <DepositText color={theme.colors.text}>{TranslateString(107, 'Deposit Methods: ')}</DepositText>
              <RowBetween padding="0 10px">
                <DepFlex>
                <DepMet>Debit Card</DepMet>
                <DepMet>Digital currencies</DepMet>
                </DepFlex>
              </RowBetween>
            </AutoColumn>
          </FiatCardBody>
        </AutoColumn>
        </FiatBlock>
        </Cards>

        <Cards>
        <FiatBlock>
          <FiatHeader>
        <FiatLogoBlock>
          <FiatImg src="/images/fiat-24paybank.svg"/>
        </FiatLogoBlock>
        <FiatPageHeader
          title={TranslateString(262, 'Available Operations')}
          description={TranslateString(1168, 'Fiat in / Fiat Out')}
        >
          <ParFiat>
          Exchange service 24paybank.net provides high-quality and fast exchange operations since December 2015. The exchange takes place in 15 minutes at any time of the day. Our service works seven days a week, online support works 24 hours a day. We try to create the best conditions taking into account your wishes.
          </ParFiat>
          <Button scale="md" variant="secondary" style={{ float: "left", width: "200px" }} margin="0px 10px 20px 10px" as={LinkExternal} href="https://24paybank.net/?rid=15697556910357">
            {TranslateString(168, ' BUY ')}
          </Button>
          <Button variant="tertiary" style={{ background: theme.colors.tertiary, width: "200px" }} margin="0px 10px 20px 10px" as={LinkExternal} href="https://24paybank.net/?rid=15697556910357">
            {TranslateString(168, ' SELL ')}
          </Button>
        </FiatPageHeader>
        </FiatHeader>
        <AutoColumn gap="lg" justify="center">
          <FiatCardBody>
            <AutoColumn gap="12px" style={{ width: '100%' }}>
            <DepositText color={theme.colors.text}>{TranslateString(107, 'Deposit Methods: ')}</DepositText>
              <RowBetween padding="0 10px">
                <DepFlex>
                <DepMet>Debit Card</DepMet>
                <DepMet>Digital currencies</DepMet>
                </DepFlex>
              </RowBetween>
            </AutoColumn>
          </FiatCardBody>
        </AutoColumn>
        </FiatBlock>
        </Cards>

        </MainWrapper>
      </AppBody>
    </>
  )
}
