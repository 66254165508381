import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Heading, Text, Flex } from '@pancakeswap-libs/uikit'
import useI18n from 'hooks/useI18n'

interface PageHeaderProps {
  title: ReactNode
  description?: ReactNode
  children?: ReactNode
}

const StyledPageHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.tertiary};
  padding: 24px;
`

const Details = styled.div`
  flex: 1;
`

const FiatPageHeader = ({ title, description, children }: PageHeaderProps) => {
  const TranslateString = useI18n()

  return (
    <StyledPageHeader>
      <Flex alignItems="center">
        <Details>
          <Heading mb="8px">{title}</Heading>
          {description && (
            <Text color="textSubtle" fontSize="18px">
              {description}
            </Text>
          )}
        </Details>
      </Flex>
      {children && <Text mt="16px">{children}</Text>}
    </StyledPageHeader>
  )
}

export default FiatPageHeader
