import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { ResetCSS } from '@pancakeswap-libs/uikit'
import GlobalStyle from './style/Global'
import App from './pages/App'
import ApplicationUpdater from './state/application/updater'
import ListsUpdater from './state/lists/updater'
import MulticallUpdater from './state/multicall/updater'
import TransactionUpdater from './state/transactions/updater'
import Providers from './Providers'
import 'inter-ui'
import './i18n'

const Help = styled.a`
width: 124px;
height: 60px;
position: sticky;
top: calc(100vh - 90px);
display: block;
margin-top: -60px;
left: calc(100vw - 148px);
z-index: 100000;
${({ theme }) => theme.mediaQueries.sm} {
  width: 224px;
  height: 60px;
  left: calc(100vw - 248px);
}

${({ theme }) => theme.mediaQueries.lg} {
  width: 224px;
  height: 60px;
  left: calc(100vw - 248px);
}
`

const NeedHelp = styled.div`
background: url(/images/help-min.svg) no-repeat center center;
width: i;
height: 100%;
display: block;
&:hover {
  opacity: 0.7;
}
${({ theme }) => theme.mediaQueries.sm} {
  background: url(/images/help.svg) no-repeat center center;
}

${({ theme }) => theme.mediaQueries.lg} {
  background: url(/images/help.svg) no-repeat center center;
}
`

if ('ethereum' in window) {
  (window.ethereum as any).autoRefreshOnNetworkChange = false
}

window.addEventListener('error', () => {
   localStorage?.removeItem('redux_localstorage_simple_lists')
})

ReactDOM.render(
  <StrictMode>
    <Providers>
      <>
        <ListsUpdater />
        <ApplicationUpdater />
        <TransactionUpdater />
        <MulticallUpdater />
      </>
      <ResetCSS />
      <GlobalStyle />
      <Help href="https://docs.mainfarm.io/ambassadors" target="_blank"><NeedHelp /></Help>
      <App />
    </Providers>
  </StrictMode>,
  document.getElementById('root')
)
